exports.list = {
    path: '/lokasi',
    name: 'Lokasi', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/lokasi/List.vue"),
}
exports.show = {
    path: '/lokasi/show/:kode',
    name: 'Lokasi_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/lokasi/Show.vue"),
}