exports.list = {
    path: '/penjualan_aktiva',
    name: 'penjualan_aktiva', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/penjualan_aktiva/List.vue"),
}
exports.show = {
    path: '/penjualan_aktiva/show/:no_kk',
    name: 'penjualan_aktiva_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/penjualan_aktiva/Show.vue"),
}