exports.list = {
    path: '/satuan',
    name: 'satuan', 
      meta: {
        authRequired: true,
      },
    component: () => import("@/views/main/satuan/List.vue"),
}
exports.show = {
    path: '/satuan/show/:kode',
    name: 'satuan_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/satuan/Show.vue"),
}