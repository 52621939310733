exports.group = {
    path: '/group',
    name: 'group', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/role/groupList.vue"),
}
exports.group_show = {
    path: '/group/show/:id',
    name: 'group_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/role/groupShow.vue"),
}
exports.user = {
    path: '/user',
    name: 'user', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/role/UserList.vue"),
}
exports.user_show = {
    path: '/user/show/:kode',
    name: 'user_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/role/userShow.vue"),
}
exports.user_customer = {
  path: "/user_customer",
  name: "user_customer",
  meta: {
    authRequired: true,
  },
  component: () => import("@/views/main/role/UserCustomerList.vue"),
};
exports.user_customer_show = {
  path: "/user_customer/show/:kode",
  name: "user_customer_show",
  meta: {
    authRequired: true,
  },
  component: () => import("@/views/main/role/userCustomerShow.vue"),
};
exports.group_cabang = {
    path: '/group_cabang',
    name: 'group_cabang', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/role/GroupCabangList.vue"),
}
exports.group_cabang_show = {
    path: '/group_cabang/show/:id',
    name: 'group_cabang_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/role/GroupCabangShow.vue"),
}
exports.user_edit_password = {
    path: '/user/edit_password',
    name: 'user_edit_password', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/role/EditPassword.vue"),
}
exports.administrator = {
    path: '/administrator',
    name: 'administrator', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/role/AdministratorList.vue"),
}
exports.administrator_show = {
    path: '/administrator/show/:kode',
    name: 'administrator_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/role/AdministratorShow.vue"),
}