exports.list = {
    path: '/modules',
    name: 'modules', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/modules/List.vue"),
}
exports.show = {
    path: '/modules/show/:id',
    name: 'modules_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/modules/Show.vue"),
}
  