exports.list = {
    path: '/fifo',
    name: 'Fifo', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/fifo/List.vue"),
}
exports.show = {
    path: '/fifo/show/:id',
    name: 'fifo_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/fifo/Show.vue"),
}