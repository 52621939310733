exports.list = {
    path: '/mutasi_alokasi',
    name: 'mutasi_alokasi_list', 
    meta: {
      authRequired: true,
    },
    component: () => import("@/views/main/mutasi_alokasi/List.vue")
};
exports.show = {
    path: '/mutasi_alokasi/show/:no_transaksi',
    name: 'mutasi_alokasi_show', 
    meta: {
      authRequired: true,
    },
    component: () => import("@/views/main/mutasi_alokasi/Show.vue")
};