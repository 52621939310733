exports.list = {
    path: '/penyusutan_asuransi',
    name: 'penyusutan_asuransi', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/penyusutan_asuransi/List.vue"),
}
exports.show = {
    path: '/penyusutan_asuransi/show/:no_kk',
    name: 'penyusutan_asuransi_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/penyusutan_asuransi/Show.vue"),
}