exports.list = {
    path: '/kas_bank_keluar',
    name: 'kas_bank_keluar', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/kas_bank_keluar/List.vue"),
}
exports.show = {
    path: '/kas_bank_keluar/show/:no_kk',
    name: 'kas_bank_keluar_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/kas_bank_keluar/Show.vue"),
}