exports.list = {
    path: '/pembelian_dalam_negri',
    name: 'pembelian_dalam_negri', 
      meta: {
        authRequired: true,
      },
    component: () => import("@/views/main/pembelian_dalam_negri/List.vue"),
}
exports.show = {
  path: '/pembelian_dalam_negri/show/:no_faktur',
  name: 'pembelian_dalam_negri_show', 
      meta: {
          authRequired: true,
      },
  component: () => import("@/views/main/pembelian_dalam_negri/Show.vue"),
}