exports.list = {
    path: '/asuransi',
    name: 'asuransi', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/asuransi/List.vue"),
}
exports.show = {
    path: '/asuransi/show/:kode',
    name: 'asuransi_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/asuransi/Show.vue"),
}