exports.post = {
    path: '/login',
    name: 'login', 
    component: () =>{
        const mode = JSON.parse(window.mode_login)
        // console.log(mode, typeof mode , mode.mode_otp)
        if(mode?.mode_otp == 1){
            return import("@/views/main/Login_Otp.vue") 
        }else{
            return import("@/views/main/Login.vue") 
        }

       
    }
}