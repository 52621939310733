exports.list = {
  path: "/urai_barang",
  name: "urai_barang",
  meta: {
    authRequired: true,
  },
  component: () => import("@/views/main/urai_barang/List.vue"),
};
exports.show = {
  path: "/urai_barang/show/:kode",
  name: "urai_barang_show",
  meta: {
    authRequired: true,
  },
  component: () => import("@/views/main/urai_barang/Show.vue"),
};
  