exports.list = {
    path: '/tutup_so',
    name: 'tutup_so', 
      meta: {
        authRequired: true,
      },
    component: () => import("@/views/main/tutup_so/List.vue"),
}
exports.show = {
  path: '/tutup_so/show/:no_transaksi',
  name: 'tutup_so_show', 
      meta: {
          authRequired: true,
      },
  component: () => import("@/views/main/tutup_so/Show.vue"),
}