exports.list = {
    path: '/biaya',
    name: 'biaya', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/biaya/List.vue"),
}
exports.show = {
    path: '/biaya/show/:kode',
    name: 'biaya_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/biaya/Show.vue"),
}