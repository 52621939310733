exports.list = {
    path: '/nota_dk_piutang',
    name: 'nota_dk_piutang', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/nota_dk_piutang/List.vue"),
}
exports.show = {
    path: '/nota_dk_piutang/show/:no_kk',
    name: 'nota_dk_piutang_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/nota_dk_piutang/Show.vue"),
}