exports.list = {
  path: '/jurnal',
  name: 'jurnal', 
    meta: {
      authRequired: true,
    },
  component: () => import("@/views/main/jurnal/List.vue"),
}
exports.show = {
  path: '/jurnal/show/:id',
  name: 'jurnal_show', 
      meta: {
          authRequired: true,
      },
  component: () => import("@/views/main/jurnal/Show.vue"),
}