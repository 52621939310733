exports.list = {
    path: '/kas_keluar',
    name: 'kas_keluar', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/kas_keluar/List.vue"),
}
exports.show = {
    path: '/kas_keluar/show/:no_kk',
    name: 'kas_keluar_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/kas_keluar/Show.vue"),
}