exports.list = {
    path: '/sales_order',
    name: 'sales_order', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/sales_order/List.vue"),
}
exports.show = {
    path: '/sales_order/show/:no_so',
    name: 'sales_order_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/sales_order/Show.vue"),
}