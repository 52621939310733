exports.list = {
  path: "/laporan_pembayaran_hd",
  name: "laporan_pembayaran_hd",
  meta: {
    authRequired: true,
  },
  component: () => {
    return import("@/views/main/laporan_pembayaran_hd/List.vue")
  }
};
  