exports.list = {
    path: '/hutang_dagang_ln',
    name: 'hutang_dagang_ln', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/hutang_dagang_ln/List.vue"),
}
exports.show = {
    path: '/hutang_dagang_ln/show/:no_kk',
    name: 'hutang_dagang_ln_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/hutang_dagang_ln/Show.vue"),
}