exports.list = {
    path: '/master_filter',
    name: 'Master_filter', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/master_filter/List.vue"),
}
exports.show = {
    path: '/master_filter/show/:kode',
    name: 'Master_filter_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/master_filter/Show.vue"),
}