exports.list = {
    path: '/list_export_penjualan',
    name: 'list_export_penjualan', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/list_export_penjualan/List.vue"),
}
exports.show = {
    path: '/list_export_penjualan/show/:id',
    name: 'list_export_penjualan_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/list_export_penjualan/Show.vue"),
}