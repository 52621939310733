exports.list = {
  path: "/proses_vulkanisir",
  name: "proses_vulkanisir",
  meta: {
    authRequired: true,
  },
  component: () => import("@/views/main/proses_vulkanisir/List.vue"),
};
exports.show = {
  path: "/proses_vulkanisir/show/:kode",
  name: "proses_vulkanisir_show",
  meta: {
    authRequired: true,
  },
  component: () => import("@/views/main/proses_vulkanisir/Show.vue"),
};
  