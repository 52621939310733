exports.list = {
    path: '/list_import_penjualan/:_id',
    name: 'list_import_penjualan', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/list_import_penjualan/List.vue"),
}
exports.show = {
    path: '/list_import_penjualan/show/:no_faktur',
    name: 'list_import_penjualan_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/list_import_penjualan/Show.vue"),
}