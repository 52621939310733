exports.list = {
    path: '/biaya_dimuka',
    name: 'biaya_dimuka', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/biaya_dimuka/List.vue"),
}
exports.show = {
    path: '/biaya_dimuka/show/:no_kk',
    name: 'biaya_dimuka_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/biaya_dimuka/Show.vue"),
}