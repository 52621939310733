exports.list = {
    path: '/formula_bongkar_muat',
    name: 'formula_bongkar_muat', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/formula_bongkar_muat/List.vue"),
}
exports.show = {
    path: '/formula_bongkar_muat/show/:kode',
    name: 'formula_bongkar_muat_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/formula_bongkar_muat/Show.vue"),
}