exports.list = {
    path: '/pembelian_asuransi',
    name: 'pembelian_asuransi', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/pembelian_asuransi/List.vue"),
}
exports.show = {
    path: '/pembelian_asuransi/show/:no_kk',
    name: 'pembelian_asuransi_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/pembelian_asuransi/Show.vue"),
}