exports.list = {
    path: '/retur_penjualan',
    name: 'input_retur_penjualan', 
      meta: {
        authRequired: true,
      },
    component: () => import("@/views/main/retur_penjualan/List.vue"),
}
exports.show = {
    path: '/retur_penjualan/show/:no_faktur',
    name: 'retur_penjualan_show', 
          meta: {
              authRequired: true,
          },
    component: () => import("@/views/main/retur_penjualan/Show.vue"),
}