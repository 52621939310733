exports.list = {
    path: '/master_diskon',
    name: 'Master_diskon', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/master_diskon/List.vue"),
}
exports.show = {
    path: '/master_diskon/show/:kode',
    name: 'Master_diskon_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/master_diskon/Show.vue"),
}