exports.list = {
  path: "/customer_order",
  name: "customer_order",
  meta: {
    authRequired: true,
  },
  component: () => import("@/views/main/customer_order/List.vue"),
};
exports.show = {
  path: "/customer_order/show/:kode",
  name: "customer_order_show",
  meta: {
    authRequired: true,
  },
  component: () => import("@/views/main/customer_order/Show.vue"),
};
  