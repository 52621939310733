exports.list = {
    path: '/master_mpp',
    name: 'master_mpp', 
      meta: {
        authRequired: true,
      },
    component: () => import("@/views/main/master_mpp/List.vue"),
}
exports.show = {
  path: '/master_mpp/show/:no_faktur',
  name: 'master_mpp_show', 
      meta: {
          authRequired: true,
      },
  component: () => import("@/views/main/master_mpp/Show.vue"),
}