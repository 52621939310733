exports.list = {
    path: '/armada',
    name: 'armada', 
      meta: {
        authRequired: true,
      },
    component: () => import("@/views/main/armada/List.vue"),
}
exports.show = {
    path: '/armada/show/:kode',
    name: 'armada_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/armada/Show.vue"),
}