exports.list = {
  path: "/penyelesaian_claim",
  name: "penyelesaian_claim",
  meta: {
    authRequired: true,
  },
  component: () => import("@/views/main/penyelesaian_claim/List.vue"),
};

exports.show = {
  path: "/penyelesaian_claim/show/:kode",
  name: "penyelesaian_claim_show",
  meta: {
    authRequired: true,
  },
  component: () => import("@/views/main/penyelesaian_claim/Show.vue"),
};
