exports.list = {
  path: '/branch',
  name: 'branch', 
    meta: {
      authRequired: true,
    },
  component: () => import("@/views/main/branch/List.vue"),
}
exports.show = {
  path: '/branch/show/:kode',
  name: 'branch_show', 
      meta: {
          authRequired: true,
      },
  component: () => import("@/views/main/branch/Show.vue"),
}