exports.list = {
    path: '/pembelian_luar_negri',
    name: 'pembelian_luar_negri', 
      meta: {
        authRequired: true,
      },
    component: () => import("@/views/main/pembelian_luar_negri/List.vue"),
}
exports.show = {
  path: '/pembelian_luar_negri/show/:no_faktur',
  name: 'pembelian_luar_negri_show', 
      meta: {
          authRequired: true,
      },
  component: () => import("@/views/main/pembelian_luar_negri/Show.vue"),
}