exports.list = {
    path: '/aktiva_tetap',
    name: 'aktiva_tetap', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/aktiva_tetap/List.vue"),
}
exports.show = {
    path: '/aktiva_tetap/show/:kode',
    name: 'aktiva_tetap_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/aktiva_tetap/Show.vue"),
}