exports.list = {
    path: '/barang',
    name: 'barang', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/barang/List.vue"),
  }
  exports.show = {
    path: '/barang/show/:kode',
    name: 'barang_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/barang/Show.vue"),
  }