exports.list = {
    path: '/pencairan_cek_keluar',
    name: 'pencairan_cek_keluar', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/pencairan_cek_keluar/List.vue"),
}
exports.show = {
    path: '/pencairan_cek_keluar/show/:no_kk',
    name: 'pencairan_cek_keluar_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/pencairan_cek_keluar/Show.vue"),
}