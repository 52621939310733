exports.list = {
    path: '/koreksi_plus',
    name: 'koreksi_plus', 
      meta: {
        authRequired: true,
      },
    component: () => import("@/views/main/koreksi_plus/List.vue"),
}
exports.show = {
  path: '/koreksi_plus/show/:no_transaksi',
  name: 'koreksi_plus_show', 
      meta: {
          authRequired: true,
      },
  component: () => import("@/views/main/koreksi_plus/Show.vue"),
}