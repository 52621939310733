exports.list = {
    path: '/kategori',
    name: 'kategori', 
      meta: {
        authRequired: true,
      },
    component: () => import("@/views/main/kategori/List.vue"),
}
exports.show = {
    path: '/kategori/show/:kode',
    name: 'kategori_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/kategori/Show.vue"),
}