exports.list = {
    path: '/client',
    name: 'client', 
      meta: {
        authRequired: true,
      },
    component: () => import("@/views/main/client/List.vue"),
}
exports.show = {
    path: '/client/show/:email',
    name: 'client_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/client/Show.vue"),
}