exports.list = {
    path: '/retur_pembelian',
    name: 'input_retur_pembelian', 
      meta: {
        authRequired: true,
      },
    component: () => import("@/views/main/retur_pembelian/List.vue"),
}
exports.show = {
  path: '/retur_pembelian/show/:no_faktur',
  name: 'retur_pembelian_show', 
        meta: {
            authRequired: true,
        },
  component: () => import("@/views/main/retur_pembelian/Show.vue"),
}